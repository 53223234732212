import * as React from "react";
import PropTypes from "prop-types";
import { StoreWrapperDiv, StoreWrapperWrapper } from "./elements";
import { CartContext } from "./CartContext";
import StoreCart from "./../StoreCart";

// from here: https://github.com/brxck/gatsby-starter-stripe

const StoreWrapper = ({ children, done }) => {
  const { count, toggle } = React.useContext(CartContext);
  React.useEffect(() => {
    console.log("Running toggle");
    if (count > 0) {
      toggle(true);
    }
  }, []);

  return (
    <StoreWrapperWrapper>
      <StoreWrapperDiv>{children}</StoreWrapperDiv>
      {!done ? <StoreCart /> : null}
    </StoreWrapperWrapper>
  );
};

export default StoreWrapper;

StoreWrapper.propTypes = {
  done: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

StoreWrapper.defaultProps = {
  done: false,
};
