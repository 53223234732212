import * as React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
// import MiscSocialSmall from "../components/icons/MiscSocialSmall";
// import FoundationRequestInformationForm from "./../components/FoundationRequestInformationForm";
import Layout from "./../components/Layout";
import StoreWrapper from "./../components/StoreWrapper";
import StoreMasonry from "./../components/StoreMasonry";
import StoreModal from "./../components/StoreModal";
import {
  H5SmallMedium,
  H2BigStrong,
  H2Big,
  H4,
} from "./../components/Typography";

const TotePage = (
  {
    /*data, pageContext*/
  }
) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const location = useLocation();
  const toteQuery = useStaticQuery(graphql`
    {
      frontPage: craftStoreFrontPageStoreFrontPageEntry {
        callToAction
        caption
        topline
      }
      craftTote: allCraftToteItemToteItemEntry {
        nodes {
          pageSlug
          toteImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                childImageSharp {
                  fixed(width: 400) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      stripe: allStripePrice {
        group(field: product___metadata___id) {
          edges {
            node {
              unit_amount
              product {
                id
                type
                object
                name
                description
                metadata {
                  id
                }
                images
              }
            }
          }
        }
      }
    }
  `);

  const frontPageData = toteQuery.frontPage;
  const stripeData = toteQuery.stripe.group;
  // console.log(stripeData);
  const craftToteData = toteQuery.craftTote.nodes;

  // console.log(stripeData, craftToteData);

  const makeList = (stripeData, craftToteData) => {
    let outList = [];
    for (let i = 0; i < stripeData.length; i++) {
      const thisId = stripeData[i].edges[0].node.product.metadata.id;
      let found = false;
      for (let j = 0; j < craftToteData.length; j++) {
        if (thisId === craftToteData[j].pageSlug) {
          // console.log("found!", thisId);
          if (
            craftToteData[j].toteImage &&
            craftToteData[j].toteImage.length &&
            craftToteData[j].toteImage[0].localFile
          ) {
            found = true;
            outList[outList.length] = {
              image: craftToteData[j].toteImage[0],
              id: stripeData[i].edges[0].node.product.metadata.id,
              name: stripeData[i].edges[0].node.product.name,
              ...stripeData[i],
            };
          }
        }
      }
      if (!found) {
        console.log("not found: ", thisId);
      }
    }
    // console.log(outList);
    return outList;
  };

  const combinedList = makeList(stripeData, craftToteData);

  React.useEffect(() => {
    if (location.search && location.search.indexOf("success=true") > -1) {
      setModalOpen(true);
    }
  }, [location]);

  return (
    <Layout backgroundColor={"var(--white)"} footerColor={"var(--purple)"}>
      <StoreWrapper done={modalOpen}>
        {/*<MiscSocialSmall color={"var(--purple"} />*/}
        <H5SmallMedium></H5SmallMedium>
        <div className="storeheaderwrapper">
          <H2BigStrong>{frontPageData.topline}</H2BigStrong>
          <H2Big>{frontPageData.caption}</H2Big>
        </div>
        {stripeData.length ? (
          <StoreMasonry items={combinedList} />
        ) : (
          <H4>{toteQuery.callToAction}</H4>
        )}
      </StoreWrapper>
      {/*<FoundationRequestInformationForm storeVersion />*/}
      {modalOpen ? (
        <StoreModal
          onClose={() => {
            setModalOpen(false);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default TotePage;

TotePage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
