import styled from "styled-components";

export const StoreMasonryWrapper = styled.div`
  --itemWidth: 400px;
  --gutter: 20px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  & .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: calc(0px - var(--gutter)); /* gutter size offset */
    width: auto;
  }
  & .my-masonry-grid_column {
    margin-left: var(--gutter); /* gutter size */
    background-clip: padding-box;
    width: var(--itemWidth) !important;
    box-sizing: border-box;
    &:nth-child(1),
    &:nth-child(5) {
      margin-top: 500px;
    }
    &:nth-child(3),
    &:nth-child(7) {
      margin-top: 300px;
    }
    &:nth-child(4),
    &:nth-child(8) {
      margin-top: 115px;
    }
  }
  @media (max-width: 767px) {
    & .my-masonry-grid_column {
      &:nth-child(1),
      &:nth-child(5) {
        margin-top: 0;
      }
      &:nth-child(3),
      &:nth-child(7) {
        margin-top: 0;
      }
      &:nth-child(4),
      &:nth-child(8) {
        margin-top: 0;
      }
    }
  }
`;

export const StoreItemDiv = styled.div`
  --itemWidth: 400px;
  width: var(--itemWidth);
  margin-bottom: 150px;
  & .placeholder {
    width: var(--itemWidth);
    height: var(--itemWidth);
    background-color: var(--red);
  }
  & .gatsby-image-wrapper {
    min-width: var(--itemWidth);
    border-bottom: 3px solid var(--purple);
  }
  & .bottom {
    padding-top: 10px;
    display: flex;
    color: var(--purple);
    justify-content: space-between;
    & p {
      margin: 0;
    }
    & a {
      display: inline-flex;
      justify-content: center;
      user-select: none;
      align-items: center;
      outline: none;
      border: none;
      color: var(--white);
      background-color: var(--purple);
      width: 190px;
      height: 36px;
      font-family: var(--headerFont);
      font-size: 15px;
      font-weight: 500;
    }
  }
  @media (max-width: 767px) {
    --gutter: 0;
    --itemWidth: calc(100vw - 40px);
    margin-bottom: 50px;
    & .gatsby-image-wrapper {
      width: var(--itemWidth) !important;
    }
  }
`;
