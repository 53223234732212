import styled from "styled-components";

export const StoreModalDiv = styled.div`
  --width: 500px;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  color: var(--purple);
  top: 300px;
  left: calc(50vw - calc(var(--width) / 2));
  padding: 30px;
  z-index: 999;
  width: var(--width);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  & > header {
    display: flex;
    margin-bottom: 25px;
    & > svg {
      margin-left: auto;
    }
  }
  & h5 {
    color: var(--black);
    line-height: 30px;
    & + h5 {
      margin-top: 18px;
    }
  }
`;
