import styled from "styled-components";

export const StoreWrapperWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
`;

export const StoreWrapperDiv = styled.div`
  --storeOverlap: 68px;
  --sidePadding: 0px;
  max-width: var(--width);
  width: var(--width);
  margin: 0 auto var(--outerMargin);
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.5s;
  z-index: 2;
  padding: 0 var(--sidePadding);
  & > h5 {
    color: var(--black);
    margin-top: 15px;
    margin-bottom: 30px;
    z-index: 2;
    & a {
      color: var(--black);
    }
  }
  & .storeheaderwrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-bottom: calc(0px - var(--storeOverlap));
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #ffffff 39.02%,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
    }
    & > h2 {
      margin: 0;
      color: var(--purple);
      z-index: 2;
    }
  }
  & > h4 {
    margin-top: var(--outerMargin);
  }
  @media (max-width: 1439px) {
    --sidePadding: 20px;
    --storeOverlap: 0;
    & .storeheaderwrapper h2 {
      font-size: 42px;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    & .my-masonry-grid {
      width: 100%;
      margin-left: 0px;
      margin-top: 25px;
      & .my-masonry-grid_column {
        --gutter: 0px !important;
        --itemWidth: calc(100% - 40px) !important;
        width: 100% !important;
      }
    }
  }
`;
