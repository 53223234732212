import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";
import { StoreMasonryWrapper, StoreItemDiv } from "./elements";

const getPricesFromEdges = (edges) => {
  const prices = edges.map((x) => x.node.unit_amount);
  let min = prices[0];
  let max = prices[0];
  for (let i = 0; i < prices.length; i++) {
    if (prices[i] < min) {
      min = prices[i];
    }
    if (prices[i] > max) {
      max = prices[i];
    }
  }
  if (min === max) {
    return String(min);
  } else {
    return `${min / 100}–${max / 100}`;
  }
};

const StoreItem = ({ itemData }) => {
  // console.log(itemData);
  const thisName = itemData.name;
  const thisPrice = getPricesFromEdges(itemData.edges);
  const thisSlug = itemData.id;
  return (
    <StoreItemDiv>
      <Link to={`/tote/${thisSlug}`}>
        {itemData.image ? (
          <Img
            fixed={itemData.image.localFile.childImageSharp.fixed}
            alt={thisName}
          />
        ) : (
          <div className="placeholder" />
        )}
      </Link>
      <div className="bottom">
        <div>
          <p>
            <strong>{thisName}</strong>
          </p>
          <p>{itemData.type}</p>
          <p>{itemData.dimensions}</p>
          <p>${thisPrice}</p>
          <p>{itemData.edition}</p>
        </div>
        <Link to={`/tote/${thisSlug}`}>See more</Link>
      </div>
    </StoreItemDiv>
  );
};

const StoreMasonry = ({ items }) => {
  return (
    <StoreMasonryWrapper>
      <Masonry
        breakpointCols={{ default: 3, 1240: 3, 820: 2, 400: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {items.map((item, index) => (
          <StoreItem key={index} itemData={item} />
        ))}
      </Masonry>
    </StoreMasonryWrapper>
  );
};

export default StoreMasonry;

StoreMasonry.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      dimensions: PropTypes.string,
      price: PropTypes.number,
      edition: PropTypes.string,
      image: PropTypes.object,
      slug: PropTypes.string,
    })
  ),
};
