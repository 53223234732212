import * as React from "react";
import PropTypes from "prop-types";
import CloseButton from "./../icons/CloseButton";
import { H3Strong, H5Big } from "./../Typography";
import { StoreModalDiv } from "./elements";

const StoreModal = ({ onClose }) => {
  return (
    <StoreModalDiv>
      <header>
        <H3Strong>Your tote order is on the{"\u00a0"}way ⚡</H3Strong>
        <CloseButton
          color={"var(--purple)"}
          onClick={() => {
            onClose(true);
          }}
        />
      </header>
      <div>
        <H5Big>
          Look out for a confirmation email from our team regarding how to
          collect your item. If you have any other concerns, email us at
          <a href="mailto:info@alserkal.online">info@alserkal.online</a>.
        </H5Big>
        <H5Big>Thanks for filling your Tote at alserkal.online!</H5Big>
      </div>
    </StoreModalDiv>
  );
};

export default StoreModal;

StoreModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
