import styled from "styled-components";

export const StoreCartWrapper = styled.div`
  z-index: 1;
`;

export const CartWrapper = styled.div`
  --cartWidth: 600px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  z-index: 9;
  /* display: ${(props) => (props.mode ? "initial" : "none")}; */
  display: initial;
  transform: translateX(${(props) => (props.mode ? 0 : "var(--cartWidth)")});
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  padding: var(--outerMargin) 82px var(--outerMargin) 68px;
  background-color: var(--light);
  max-width: var(--cartWidth);
  width: 100%;
  transition: 1s;
  & > svg {
    position: absolute;
    left: 68px;
    top: 68px;
  }
  & h3 {
    color: var(--purple);
    margin-top: 38px;
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    --cartWidth: 100vw;
    padding: 25px;
    & > svg {
      left: initial;
      right: 25px;
      top: 25px;
    }
  }
`;

export const CartItemContainer = styled.div`
  display: flex;
  margin-bottom: 44px;
  position: relative;
  & a {
    margin-right: 1rem;
    color: var(--black);
    & img {
      width: 100px;
      max-height: 100px;
      object-fit: contain;
    }
  }
`;

export const CartItemDescription = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    & div + div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & strong {
        font-weight: 400;
        font-size: 15px;
        display: block;
        white-space: nowrap;
      }
    }
  }
`;

export const CartItemQuantity = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CartItemRemove = styled.button`
  color: var(--purple);
  border: none;
  outline: none;
  padding: 0;
  font-family: var(--headerFont);
  font-size: 15px;
  margin-top: 5px;
  font-weight: 700;
`;

export const CheckoutSection = styled.div`
  & h4 {
    font-size: 24px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 700;
    align-items: center;
    color: var(--black);
    margin: 40px 0;
    & > em {
      font-size: 15px;
      font-weight: 400;
    }
  }
  & > button {
    height: 56px;
    font-size: 24px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: none;
    outline: none;
    width: 100%;
    background-color: var(--purple);
    color: var(--white);
    font-family: var(--headerFont);
  }
`;
