import * as React from "react";
import { CartContext } from "./../StoreWrapper/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutSection } from "./elements";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const { cart, total } = React.useContext(CartContext);

  const onClick = () => {
    const lineItems = cart.map(([price, quantity]) => ({
      price: price.id,
      quantity,
    }));
    // console.log(lineItems);
    fetch("/.netlify/functions/orderCreate", {
      method: "POST",
      body: JSON.stringify(lineItems),
    })
      .then(async (response) => {
        const { id } = await response.json();
        localStorage.setItem("cart", "{}");
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: id });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        alert(error.message);
      })
      .catch((err) => alert(err.message));
  };

  return (
    <CheckoutSection>
      <h4>
        <span>Total</span>
        <em>Shipping included</em>
        <span>AED {total / 100}</span>
      </h4>
      <button onClick={onClick}>Checkout for AED{total / 100}</button>
    </CheckoutSection>
  );
};

export default Checkout;
