import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import {
  CartItemContainer,
  CartItemRemove,
  CartItemDescription,
  CartItemQuantity,
} from "./elements";

import { CartContext } from "./../StoreWrapper/CartContext";

const CartItem = ({ price, quantity }) => {
  const { remove } = React.useContext(CartContext);
  return (
    <CartItemContainer key={price.id}>
      <Link to={`/tote/${price.product.metadata.id}`}>
        <img
          src={price.image || price.product.images[0]}
          alt={price.product.name}
        />
      </Link>
      <CartItemDescription>
        <div>
          <div>
            <Link to={`/tote/${price.product.metadata.id}`}>
              <div>
                <strong>{price.product.name}</strong>
              </div>
            </Link>
            <span>{price.nickname}</span>
          </div>
          <div>
            <strong>AED {(price.unit_amount / 100) * quantity}</strong>
            <CartItemRemove
              onClick={() => {
                remove(price.id);
              }}
            >
              remove
            </CartItemRemove>
          </div>
        </div>
        <CartItemQuantity>
          <span>
            AED {price.unit_amount / 100} &times; {quantity}
          </span>
        </CartItemQuantity>
      </CartItemDescription>
    </CartItemContainer>
  );
};

CartItem.propTypes = {
  price: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default CartItem;
